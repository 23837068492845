import React from 'react'
import { Link } from 'gatsby'

const NavLink = ({ children, slug, level }) => {
  return (
    <Link
      to={slug.endsWith('/') ? `/${slug}` : `/${slug}/`}
      activeClassName="is-active"
      className="c-list-item--link ln-c-list-group__item ln-c-header__nav-item"
      style={{
        paddingLeft: `calc(var(--ds-space-spacing-x2) * ${level})`,
      }}
    >
      {children}
    </Link>
  )
}

export default NavLink

import React, { createContext, useContext } from 'react'

const NavContext = createContext({
  path: '',
})

export const NavProvider = ({ path, children }) => (
  <NavContext.Provider value={{ path }}>{children}</NavContext.Provider>
)

export const useNavContext = () => useContext(NavContext)

export default NavContext

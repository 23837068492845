import { createContext } from 'react'

export const ExamplesThemeContext = createContext({
  theme: {
    name: 'sainsburys',
    punctualName: "Sainsbury's",
  },
  setTheme: () => {},
  hasThemeSelector: true,
})
export const ExamplesThemeProvider = ExamplesThemeContext.Provider
export const ExamplesThemeConsumer = ExamplesThemeContext.Consumer

import React from 'react' 

import { QueryStyleGuideHeadings } from './headingsQuery'

import { Button } from '@jsluna/react'


const AZNavigation = () => {
    const data = QueryStyleGuideHeadings()
    
    const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
    
    const lettersOnPage = data.map(datum => datum.value).map(heading => heading.charAt(0).toUpperCase())
    const uniqueLettersOnPage = [...new Set(lettersOnPage)]


    return (   
        <div className='c-az-navigation' role='navigation' aria-labelledby='a-z' aria-description='The A to Z navigation will take you to the section of the page starting with the letter you choose'>
            <h3 className='ln-u-visually-hidden' id='a-z'>A to Z</h3>
            <p className='ln-u-visually-hidden' id='description'>The A to Z navigation  will take you to the section of the page starting with the letter you choose</p>  
            <ol>
                {alphabet.map((letter) => {
                    if(uniqueLettersOnPage.find(letterOnPage => letterOnPage === letter) === letter) {
                        const firstSectionHeadingWithMatchingLetter = data.map(datum => datum.value).find(heading => heading.charAt(0).toUpperCase() === letter)
                        /* Regex based off github algorithm which is used by gatsby-remark-autolink-headers to create ids for headings https://github.com/Flet/github-slugger/blob/master/regex.js */
                        const regex = /[\0-\x1F!-,\.\/:-@\[-\^`]/g
                        const hrefFormat = '#'+firstSectionHeadingWithMatchingLetter.toLowerCase().replace(regex, '').replace(/ /g, '-')
                        
                        return <li><a id={letter} aria-describedby='description' className='ln-c-button c-az-navigation__button--exists' href={hrefFormat}>{letter}</a></li>
                    }
                    else {
                        return <li><Button id={letter} disabled>{letter}</Button></li>
                    }

                })}
            </ol> 
        </div> 
    )
}

export default AZNavigation
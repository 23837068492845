import React from 'react'
import Nav from './Nav'
import ChildNavItems from './ChildNavItems'

const NavItems = ({ nav, parent }) => {
  return (
    <Nav nav={nav} parent={parent}>
      {({ navList, node }) => <ChildNavItems nav={navList} parent={node} />}
    </Nav>
  )
}

export default NavItems

import * as R from 'ramda'

const getNavWithParent = data =>
  data.allMdx.nodes.map(node => {
    const slug = node.slug.split('/').filter(x => x)
    const level = slug.length
    return {
      ...node,
      level,
      mainCategory: slug[0] || null,
      parent:
        level > 1
          ? {
              category: slug[level - 2] || null,
              level: level - 1,
              slug: `${slug.slice(0, -1).join('/')}/`,
            }
          : null,
    }
  })

// see https://stackoverflow.com/questions/46645667/how-to-turn-flat-list-into-hierarchical-one-using-ramda
// see https://ramdajs.com/docs/

const buildHierarchichalNav = navListItems => {
  const getChildren = listItems => parent =>
    R.filter(
      R.and(
        R.pathEq(['parent', 'level'], parent.level),
        R.pathEq(['parent', 'slug'], parent.slug),
      ),
      listItems,
    )

  const setChildren = listItems =>
    R.compose(
      R.map(
        R.compose(
          parent =>
            R.over(R.lensProp('children'), setChildren(listItems), parent),
          parent => R.assoc('children', getChildren(listItems)(parent), parent),
        ),
      ),
      R.reject(R.compose(R.isEmpty, R.path(['frontmatter', 'title']))),
    )

  return R.compose(
    R.filter(R.compose(R.isNil, R.prop('parent'))),
    setChildren(navListItems),
  )(navListItems)
}

const getActivePath = level =>
  R.compose(R.join('/'), R.take(level), R.reject(R.isEmpty), R.split('/'))

const isActivePath = (path, level) => slug => {
  const activePath = getActivePath(level)(path)

  return R.equals(slug, `${activePath}/`)
}

export { getNavWithParent, buildHierarchichalNav, isActivePath }

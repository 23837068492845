import React from 'react'
import Navigation from './Nav'
import NavItems from './NavItems'

const ChildNavItems = ({ nav, parent, path }) => {
  return (
    <Navigation nav={nav} parent={parent}>
      {({ navList, node }) => <NavItems nav={navList} parent={node} />}
    </Navigation>
  )
}

export default ChildNavItems

import React from 'react'


const BackToTopLink = () => {
    return (
        <div className='c-back-to-top-link-wrapper'>
            <a className='ln-c-link' href='#top'>
                Back to top
            </a>
        </div>
    )
}

export default BackToTopLink
import { useStaticQuery, graphql } from 'gatsby'

export const QueryStyleGuideHeadings = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(filter: { frontmatter: { title: { eq: "Style guide" } } }) {
        nodes {
          headings(depth: h2) {
            value
          }
          frontmatter {
            title
          }
        }
      }
    }
  `)

  return data.allMdx.nodes[0].headings
}

import React from 'react'

import { ToggleButtonGroup, ToggleButton } from '@jsluna/toggle-button'
import { Heading2 } from '@jsluna/typography'
import { Like, Dislike } from '@jsluna/icons'

const buttons = [
  { label: 'Helpful', value: 'helpful', icon: Like },
  { label: 'Unhelpful', value: 'unhelpful', icon: Dislike },
]

const FeedbackThumbs = ({ children, title }) => {
  const [buttonGroupValue, setButtonGroupValue] = React.useState()
  return (
    <>
      <Heading2>{title ? title : 'Was this page helpful?'}</Heading2>
      {children}
      <ToggleButtonGroup
        inline
        alpha
        label="Helpful / Unhelpful"
        disabled={buttonGroupValue}
        value={buttonGroupValue}
        onChange={(event, value) => {
          typeof window !== 'undefined' &&
            process.env.NODE_ENV === 'production' &&
            window.gtag('event', 'feedback', {
              value: value === 'helpful' ? 1 : 0,
              label: value,
              pathname: event.view.location.pathname,
            })
          setButtonGroupValue(buttonGroupValue !== value ? value : undefined)
        }}
      >
        {buttons.map(({ label, value, icon: Icon }) => (
          <ToggleButton key={value} value={value}>
            <Icon className="ln-u-margin-right" />
            {label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {buttonGroupValue && (
        <p className="ln-u-push-top" role="alert" aria-live="polite">
          Thanks for your feedback. We’ll use it to improve our guidelines going
          forward.
        </p>
      )}
    </>
  )
}

export default FeedbackThumbs

import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'
import { ExamplesThemeProvider } from '@utils/theme'
import { Box as Box1 } from '@sainsburys-tech/fable'
import { Page, PageBody, PageFooter } from '@jsluna/site-layout'
import { Container, GridWrapper, GridItem } from '@jsluna/grid'
import {
  ButtonGroupWrapper,
  ButtonGroupPrimary,
  ButtonGroupSecondary,
} from '@jsluna/button'
import { Card } from '@jsluna/card'
import { TextInputField } from '@jsluna/form'
import { Link } from '@jsluna/link'
import { Section } from '@jsluna/section'
import { Body2, Display2, Display1 } from '@jsluna/typography'

import ThemeContext from '../../context/ThemeContext'
import Button from '../button'
import Sidebar from '../sidebar'
import Footer from '../footer'
import MarkdownLink from '../markdown-link'
import GridList, { GridListItem } from '../grid-list'
import LogosContainer from '../logos-container/index.jsx'
import Masthead from '../masthead'

import ThemeSelector from '../theme-selector'
import GetInTouch from '../get-in-touch'
import {
  Tabs,
  Tab,
  TableTab,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
} from '../tabs'
import TokensLookup from '../tokens-lookup'
import { TableCellResult } from '../design-tokens'

import ImageWithCaption from '../image-with-caption'
import StatePageBrandExamplesSection from '../state-page-brand-examples'
import AZNavigation from '../az-navigation'
import BackToTopLink from '../back-to-top-link'
import FeedbackThumbs from '../feedback-thumbs'

import HeadHelmet from '@components/head-helmet'

import links from '@utils/externalLinks'
import TableOfContents from '@components/table-of-contents'
import { Guideline, Do, Dont, Box } from '@components/guideline'

import { useLocalStorage } from '@utils/useLocalStorage'

//Fable Component Examples
import {
  AccordionExample,
  AccordionWithFooterExample,
} from '../Fable/accordion-example'
import {
  PrimaryAlertExampleBundle,
  SecondaryAlertExampleBundle,
  StandardAlertExample,
  StandardAlertDismissableExample,
  InformationAlertExample,
  ErrorAlertExample,
  WarningAlertExample,
  SuccessAlertExample,
} from '../Fable/alert-example'
import BreadCrumbsExample, {
  BreadcrumbsCompactExample,
} from '../Fable/breadcrumbs-example'
import PrimaryButtonExample, {
  SecondaryButtonExample,
  TertiaryButtonExample,
  FullWidthButtonExample,
  LightButtonExample,
  DarkButtonExample,
  DisabledButtonsExample,
  IconButtonExample,
  IconTextButtonExample,
  CompactButtonExample,
} from '../Fable/button-example'
import CheckboxExample from '../Fable/checkbox-examples'
import InlineLinkExample, {
  StandaloneLinkExample,
  BrandedLinkExample,
} from '../Fable/link-examples'
import ListExample, { InlineListExample } from '../Fable/list-examples'
import ModalExample from '../Fable/modal-example'
import PaginationExample, {
  CompactPaginationExample,
} from '../Fable/pagination-example'
import PasswordInputExample, {
  PasswordInputWIthVisibilityToggle,
} from '../Fable/pasword-input-example'
import RadioGroupExample, {
  OutlinedRadioGroupExample,
  ErroredRadioGroupExample,
  InlineRadioGroupExample,
  StandaloneRadioButton,
} from '../Fable/radio-buttons'
import {
  SmallRating,
  LargeRating,
  BarRating,
  StarRating,
  MonochromeStarRating,
} from '../Fable/rating-examples'
import { SelectExample } from '../Fable/select-example'
import SwitchExample, {
  WithTextSwitchExample,
  OutlinedSwitchExample,
  ErrorSwitchExample,
  DisabledSwitchExample,
  StandaloneSwitchExample,
} from '../Fable/switch-example'
import {
  StepperHorizontalExample,
  StepperVerticalExample,
} from '../Fable/stepper-example'
import { PrimaryTabsExample, SecondaryTabsExample } from '../Fable/tabs-example'
import { TagIconExample, TagStandardExample } from '../Fable/tag-examples'
import {
  StandardInputExample,
  RequiredInputExample,
  SupportingTextInputExample,
  PlaceholderTextInputExample,
  ErrorMessageTextInputExample,
  IconInputExample,
  ButtonInputExample,
} from '../Fable/input-examples'
import '../../scss/main.scss'
import {
  ToggleButtonExample,
  IconToggleButtonGroup,
  MixedToggleButtonGroup,
} from '../Fable/toggle-button-example'
import {
  ToggleTipLabelExample,
  ToggleTipParagraphExample,
  DoToggleTipExample1,
  DontToggleTipExample1,
} from '../Fable/toggle-tip-example'
import {
  ProgressSpinnerExample,
  ProgressBarExample,
  DeterminateProgressSpinnerExample,
} from '../Fable/loading-indicator-examples'
import ExampleBox from '../component-display-box'
import {
  BasicFooterExample,
  DarkFooterExample,
  ContentSectionsFooterExample,
  LinkListsFooterExample,
} from '../Fable/footer-example'
import DatePickerExample from '../Fable/date-picker-example'
import HeaderExample from '../Fable/header-example'
import {
  CarouselExample,
  CarouselExampleLink,
  CarouselExampleSmallHeader,
  CarouselExampleBigHeader,
} from '../Fable/carousel-example'
import BoxExample from '../Fable/box-example'
import {
  InputRangeExample,
  InputRangeSupportingTextExample,
} from '../Fable/input-range-example'
import {
  CounterHorizontalExample,
  CounterVerticalExample,
  CounterBinIconExample,
} from '../Fable/counter-example'
import {
  CheckboxGroupVertical,
  CheckboxGroupHorizontal,
} from '../Fable/checkbox-group-example'
import {
  ComboBoxMultiSelect,
  ComboBoxMultiSelectWithSelections,
  ComboBoxMultiSelectButton,
  ComboBoxSingleSelect,
} from '../Fable/combobox-example'
import {
  ColourSwatchLinkExample,
  ColourSwatchRadioExample,
  ColourSwatchColourExample,
  ColourSwatchMetalExample,
  ColourSwatchWoodExample,
} from '../Fable/colour-swatch-example'
import {
  EnergyLabelExample,
  EnergyLabelSizeExample,
} from '../Fable/energy-label-example'
import {
  ProductCardVerticalExample,
  ProductCardHorizontalExample,
  BadgeExample,
  RatingExample,
  ButtonExample,
  ProductCardIconButtonExample,
  PriceExample,
} from '../Fable/product-card-example'
import {
  SingleLevelMenuExample,
  TwoLevelMenuExample,
  ThreeLevelMenuExample,
  DividerMenuExample,
} from '../Fable/menu-example'

import { Button as FbButton } from '@sainsburys-tech/fable'

import '@sainsburys-tech/design-tokens/dist/sainsburys/css/local/tokens.css'
import '@sainsburys-tech/design-tokens/dist/argos/css/local/tokens.css'
import '@sainsburys-tech/design-tokens/dist/habitat/css/local/tokens.css'
import '@sainsburys-tech/design-tokens/dist/tu/css/local/tokens.css'
import '@sainsburys-tech/design-tokens/dist/nectar/css/local/tokens.css'
import '@sainsburys-tech/design-tokens/dist/global/css/local/tokens.css'

const Layout = ({ pageContext, children, path }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const showThemeSelector = pageContext?.frontmatter?.showThemeSelector

  return (
    <div className="ds-theme--global">
      <GridWrapper gutterSize="zero">
        <GridItem
          size={{ xs: '1/1', sm: '1/3', md: '1/4' }}
          className="o-sticky-top c-navbar--container"
          style={{ zIndex: '999' }}
        >
          <HeadHelmet title={pageContext?.frontmatter?.title}></HeadHelmet>
          <Sidebar
            title={data.site.siteMetadata.title}
            path={path}
            hasThemeSelector={showThemeSelector}
          />
        </GridItem>
        <GridItem
          size={{ xs: '1/1', sm: '2/3', md: '3/4' }}
          id="main"
          element="main"
        >
          <Masthead
            title={pageContext?.frontmatter?.title}
            overviewTitle={pageContext?.frontmatter?.overviewTitle}
            tagline={pageContext?.frontmatter?.tagline}
          />
          <Page>
            <PageBody>
              <Container soft={true} size="xs">
                <div>
                  <MDXProvider
                    components={{
                      a: MarkdownLink,
                      AZNavigation,
                      BackToTopLink,
                      Body2,
                      Button,
                      ButtonGroupWrapper,
                      ButtonGroupPrimary,
                      ButtonGroupSecondary,
                      Box,
                      Card,
                      Display1,
                      Display2,
                      GridList,
                      GridListItem,
                      links,
                      Box1,
                      Link,
                      TokensLookup,
                      TableOfContents,
                      Guideline,
                      Do,
                      Dont,
                      FeedbackThumbs,
                      GetInTouch,
                      LogosContainer,
                      Section,
                      Tabs,
                      Tab,
                      TableTab,
                      TableHeader,
                      TableBody,
                      TableRow,
                      TableCell,
                      TableCellResult,
                      TextInputField,
                      ImageWithCaption,
                      StatePageBrandExamplesSection,
                      FbButton,

                      BreadCrumbsExample,
                      BreadcrumbsCompactExample,
                      AccordionExample,
                      AccordionWithFooterExample,
                      PrimaryButtonExample,
                      SecondaryButtonExample,
                      TertiaryButtonExample,
                      FullWidthButtonExample,
                      CompactButtonExample,
                      LightButtonExample,
                      DarkButtonExample,
                      DisabledButtonsExample,
                      CheckboxExample,
                      InlineLinkExample,
                      BrandedLinkExample,
                      StandaloneLinkExample,
                      ListExample,
                      ModalExample,
                      PaginationExample,
                      PasswordInputExample,
                      RadioGroupExample,
                      SmallRating,
                      LargeRating,
                      BarRating,
                      StarRating,
                      SelectExample,
                      SwitchExample,
                      StepperHorizontalExample,
                      StepperVerticalExample,
                      WithTextSwitchExample,
                      PrimaryTabsExample,
                      SecondaryTabsExample,
                      TagIconExample,
                      TagStandardExample,
                      StandardInputExample,
                      RequiredInputExample,
                      SupportingTextInputExample,
                      PlaceholderTextInputExample,
                      ErrorMessageTextInputExample,
                      IconInputExample,
                      ButtonInputExample,
                      ToggleButtonExample,
                      IconToggleButtonGroup,
                      MixedToggleButtonGroup,
                      ToggleTipLabelExample,
                      ToggleTipParagraphExample,
                      DoToggleTipExample1,
                      DontToggleTipExample1,
                      ProgressSpinnerExample,
                      ProgressBarExample,
                      DeterminateProgressSpinnerExample,
                      IconButtonExample,
                      IconTextButtonExample,
                      CompactPaginationExample,
                      OutlinedSwitchExample,
                      ErrorSwitchExample,
                      DisabledSwitchExample,
                      StandaloneSwitchExample,
                      ExampleBox,
                      BasicFooterExample,
                      DarkFooterExample,
                      ContentSectionsFooterExample,
                      LinkListsFooterExample,
                      InlineListExample,
                      OutlinedRadioGroupExample,
                      ErroredRadioGroupExample,
                      InlineRadioGroupExample,
                      MonochromeStarRating,
                      StandaloneRadioButton,
                      PasswordInputWIthVisibilityToggle,
                      DatePickerExample,
                      HeaderExample,
                      CarouselExample,
                      CarouselExampleLink,
                      CarouselExampleSmallHeader,
                      CarouselExampleBigHeader,
                      BoxExample,
                      InputRangeExample,
                      InputRangeSupportingTextExample,
                      CounterHorizontalExample,
                      CounterVerticalExample,
                      CounterBinIconExample,
                      CheckboxGroupVertical,
                      CheckboxGroupHorizontal,
                      ComboBoxMultiSelect,
                      ComboBoxMultiSelectWithSelections,
                      ComboBoxMultiSelectButton,
                      ComboBoxSingleSelect,
                      PrimaryAlertExampleBundle,
                      SecondaryAlertExampleBundle,
                      StandardAlertExample,
                      StandardAlertDismissableExample,
                      InformationAlertExample,
                      ErrorAlertExample,
                      WarningAlertExample,
                      SuccessAlertExample,
                      ColourSwatchLinkExample,
                      ColourSwatchRadioExample,
                      ColourSwatchColourExample,
                      ColourSwatchMetalExample,
                      ColourSwatchWoodExample,
                      EnergyLabelExample,
                      EnergyLabelSizeExample,
                      ProductCardVerticalExample,
                      ProductCardHorizontalExample,
                      BadgeExample,
                      RatingExample,
                      ButtonExample,
                      ProductCardIconButtonExample,
                      PriceExample,
                      SingleLevelMenuExample,
                      TwoLevelMenuExample,
                      ThreeLevelMenuExample,
                      DividerMenuExample,
                    }}
                  >
                    {children}
                  </MDXProvider>
                </div>
              </Container>
            </PageBody>
            <PageFooter>
              <Footer
                repoUrl={data.site.siteMetadata.repoUrl}
                isMasterBranch={process.env.LUNA_ENV === 'production'}
                pathname={pageContext}
              />
            </PageFooter>
          </Page>
        </GridItem>
      </GridWrapper>
      {showThemeSelector && <ThemeSelector />}
    </div>
  )
}

export default Layout

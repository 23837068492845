import React from 'react'
import { Helmet } from 'react-helmet'

const HeadHelmet = title => {
  const tabDescription =
    title.title === 'Sainsbury’s Design System'
      ? 'Sainsbury’s Design System'
      : `${title.title} | Sainsbury’s Design System`
  return <Helmet title={tabDescription} />
}
export default HeadHelmet

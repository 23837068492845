import React from 'react'
import { Body1 } from '@jsluna/typography'
import { FlagWrapper, FlagComponent, FlagBody } from '@jsluna/grid'
import { Card } from '@jsluna/card'
import { sentenceCase } from 'change-case'

import * as Logo from '@jsluna/images'
import { withLabel } from '@jsluna/images'
import SainsburysNectar from '@images/svg/SainsburysNectar.svg'
import ClickAndCollect from '@images/svg/ClickAndCollect.svg'
import  SainsburysBank  from '@images/svg/brands/SainsburysBank.svg'

const ArgosLogo = withLabel(Logo.Argos)
const ArgosFastTrack = withLabel(Logo.ArgosFastTrack)
const ArgosClickAndCollect = withLabel(ClickAndCollect)
const ArgosWhite = withLabel(Logo.ArgosWhite)
const Habitat = withLabel(Logo.Habitat)
const Nectar = withLabel(Logo.Nectar)
const NectarWordmark = withLabel(Logo.NectarWordmarkPurple)
const SainsburysChopChop = withLabel(Logo.SainsburysChopChop)
const SainsburysSmartShop = withLabel(Logo.SainsburysSmartShop)
const TogetherWeAreSainsburys = withLabel(Logo.TogetherWeAreSainsburys)
const Jsainsburyplc = withLabel(Logo.Jsainsburyplc)
const SainsburysFoodToOrder = withLabel(Logo.SainsburysFoodToOrder)
const Sainsburys = withLabel(Logo.Sainsburys)
const SainsburysHelpingEveryoneEatBetter = withLabel(
  Logo.SainsburysHelpingEveryoneEatBetter,
)

const SainsburysNectarLockup = withLabel(SainsburysNectar)
const Tu = withLabel(Logo.Tu)

const logos = {
  Argos: <ArgosLogo />,
  ArgosFastTrack: <ArgosFastTrack />,
  ArgosClickAndCollect: <ArgosClickAndCollect />,
  ArgosWordmark: <ArgosWhite />,
  Habitat: <Habitat />,
  Nectar: <Nectar />,
  NectarWordmark: <NectarWordmark />,
  SainsburysChopChop: <SainsburysChopChop />,
  SainsburysSmartShop: <SainsburysSmartShop />,
  TogetherWeAreSainsburys: <TogetherWeAreSainsburys />,
  Jsainsburyplc: <Jsainsburyplc />,
  SainsburysFoodToOrder: <SainsburysFoodToOrder />,
  Sainsburys: <Sainsburys />,
  SainsburysHelpingEveryoneEatBetter: <SainsburysHelpingEveryoneEatBetter />,
  SainsburysBank: <SainsburysBank />,
  SainsburysNectar: <SainsburysNectarLockup />,
  Tu: <Tu />,
}

const LogosContainer = ({ logo, title, width, label, children }) => {
  const defaultWidth = '175px'
  return (
    <Card className="c-card ln-u-margin-bottom*2">
      <FlagWrapper>
        <FlagComponent>
          <div>
            {React.cloneElement(logos[logo], {
              width: width ? `${width}px` : defaultWidth,
              label: label ? label : `${sentenceCase(logo)} logo`,
            })}
          </div>
        </FlagComponent>

        <FlagBody className="ln-u-soft-left">
          {title && <strong>{title}</strong>}
          {children && <Body1 element="div">{children}</Body1>}
        </FlagBody>
      </FlagWrapper>
    </Card>
  )
}

export default LogosContainer

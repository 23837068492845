import React from 'react'

import office from '@images/states/office.png'
import bowlOfSoup from '@images/states/bowl-of-soup.png'
import pagination from '@images/states/pagination.png'
import newCustomer from '@images/states/new-customer.png'
import whatsMyId from '@images/states/whats-my-id.png'
import modalExample from '@images/states/modal-example.png'

const StatePageBrandExamplesSection = () => {
  return (
    <div className="c-states-grid">
      <div className="c-states-grid--container">
        <div className="c-states-grid--left">
          <img src={modalExample} alt="modal example" />
          <div className="c-states-grid-left__row-2">
            <img src={office} alt="link example" />
          </div>
        </div>
        <div className="c-states-grid--right">
          <div className="c-states-grid-right__row-1">
            <img src={bowlOfSoup} alt="bowl of soup" />
            <div className="pagination-style">
              <img src={pagination} alt="pagination selector example" />
            </div>
          </div>
          <div className="c-states-grid-right__row-2">
            <img src={whatsMyId} alt="accordian example" />
          </div>
          <div className="c-states-grid-right__row-3">
            <img src={newCustomer} alt="input example" />
          </div>
        </div>
      </div>
      <p className="caption">
        Examples of our interaction states across our brands.
      </p>
    </div>
  )
}

export default StatePageBrandExamplesSection

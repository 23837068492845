import React from 'react'
import NavLink from './NavLink'
import NavAccordion from './NavAccordion'
import { isActivePath } from './helpers'
import { useNavContext } from './NavContext'

const Nav = ({ nav, parent, children }) => {
  const { path } = useNavContext()

  return (
    <ul class="ln-c-list-group ln-c-list-group--actionable ln-c-list-group--inline@nav ln-c-header__nav-list">
      {parent && (
        <li>
          <NavLink slug={parent.slug} level={parent.level + 1}>
            {parent.frontmatter.overviewTitle
              ? parent.frontmatter.overviewTitle
              : `Overview`}
          </NavLink>
        </li>
      )}
      {nav.map(node => {
        const { frontmatter, id, children: nodeChildren, slug, level } = node

        const title = frontmatter.navTitle || frontmatter.title

        return (
          <li key={id}>
            {nodeChildren.length !== 0 ? (
              <NavAccordion
                key={id}
                id={id}
                level={level}
                title={title}
                isActive={isActivePath(path, level)(slug)}
              >
                {children({
                  navList: nodeChildren,
                  node,
                })}
              </NavAccordion>
            ) : (
              <NavLink slug={slug} level={level}>
                {title}
              </NavLink>
            )}
          </li>
        )
      })}
    </ul>
  )
}

export default Nav

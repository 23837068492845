import React from 'react'
import { Footer } from '@jsluna/footer'
import { Container, FlagWrapper, FlagBody, FlagComponent } from '@jsluna/grid'
import links from '@utils/externalLinks'

const LnFooter = ({ repoUrl, pathname, isMasterBranch }) => {
  //   const splitPath = pathname.split("/").filter(Boolean);
  //   const path = pathname && `edit/master/src/pages/${pathname}index.mdx`;

  const footerLinks = [
    { title: 'Privacy hub', url: links.privacy.privacyHub },
    { title: 'Cookie settings', url: '#' },
    { title: 'Cookies policy', url: links.privacy.cookiePolicy },
    { title: 'Feedback', url: links.feedback },
  ]
  return (
    <Footer className="ln-u-soft-ends-xl c-footer">
      <Container>
        <FlagWrapper
          respondAt="md"
          className="ln-u-caption c-footer--flag-wrapper"
        >
          <FlagBody className="ln-u-push-right-lg c-footer--flag-body">
            <span className="ln-u-display-block ln-u-font-weight-bold">
              &copy; J Sainsbury plc {new Date().getFullYear()}
            </span>
            <span className="ln-u-display-block">
              The digital assets and code included in these guidelines may only
              be used with the permission of, and for the benefit of, the J
              Sainsbury plc Group.
            </span>
          </FlagBody>
          <FlagComponent alignment="top" className="c-footer--flag-component">
            <div className="ln-u-display-flex ln-u-flex-wrap">
              {footerLinks.map((link, index) => (
                <span
                  className="ln-u-display-flex ln-u-align-items-center ln-u-text-align-center"
                  key={link.url}
                >
                  <a
                    id={link.title === 'Cookie settings' ? 'ot-sdk-btn' : ''}
                    href={link.url}
                    className="ln-o-bare-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {link.title}
                  </a>
                  {index !== footerLinks.length - 1 && (
                    <span className="ln-u-push-sides-sm">|</span>
                  )}
                </span>
              ))}
            </div>
          </FlagComponent>
        </FlagWrapper>
      </Container>
    </Footer>
  )
}

export default LnFooter

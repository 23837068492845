import React from 'react'
import { Accordion, AccordionItem } from '@jsluna/accordion'

const NavAccordion = ({ children, id, level, title, isActive }) => {
  return (
    <Accordion titleElement="div">
      <AccordionItem
        id={id}
        defaultOpen={isActive}
        className={`${isActive ? 'is-active' : ''}`}
        title={
          <span
            style={{
              paddingLeft: `calc(var(--ds-space-spacing-x2) * ${level})`,
            }}
          >
            {title}
          </span>
        }
      >
        {children}
      </AccordionItem>
    </Accordion>
  )
}

export default NavAccordion

import React from 'react'
import clsx from 'clsx'

import ThemeContext from '../../context/ThemeContext'

import SainsburysFavicon from '@images/svg/favicons/sainsburys.svg'
import ArgosFavicon from '@images/svg/favicons/argos.svg'
import NectarFavicon from '@images/svg/favicons/nectar.svg'
import TuFavicon from '@images/svg/favicons/tu.svg'
import HabitatFavicon from '@images/svg/favicons/habitat.svg'

const brandThemes = [
  {
    name: 'sainsburys',
    punctualName: "Sainsbury's",
    icon: <SainsburysFavicon />,
  },
  { name: 'argos', punctualName: 'Argos', icon: <ArgosFavicon /> },
  { name: 'habitat', punctualName: 'Habitat', icon: <HabitatFavicon /> },
  { name: 'tu', punctualName: 'Tu', icon: <TuFavicon /> },
  { name: 'nectar', punctualName: 'Nectar', icon: <NectarFavicon /> },
]

const ThemeSelector = () => {
  return (
    <ThemeContext.Consumer>
      {theme => (
        <div className="c-theme-selector" id="theme-selector">
          <span
            id="theme-selector-text"
            className="ln-u-visually-hidden@max-xs"
          >
            Select a theme to demo:
          </span>
          <div role="radiogroup" aria-labelledby="theme-selector-text">
            {brandThemes.map(brandTheme => (
              <button
                key={brandTheme.name}
                onClick={() => {
                  theme.setBrand(brandTheme.name)
                  theme.setPunctuatedBrand(brandTheme.punctualName)
                }}
                className={clsx(
                  `ds-theme--${brandTheme.name}`,
                  `c-theme-selector--icon`,
                  {
                    'is-active': brandTheme.name === theme.brand,
                  },
                )}
                role="radio"
                aria-checked={brandTheme.name === theme.name ? 'true' : 'false'}
              >
                {brandTheme.icon}
              </button>
            ))}
          </div>
        </div>
      )}
    </ThemeContext.Consumer>
  )
}

export default ThemeSelector

import React from 'react'
import { Display6, Body1 } from '@sainsburys-tech/fable'
import { Container, GridWrapper, GridItem } from '@jsluna/grid'
import { pascalCase } from 'change-case'
import ReactMarkdown from 'react-markdown'

const Masthead = ({ title, tagline, overviewTitle }) => {
  const images = {
    Accessibility: 'accessibility',
    Colour: 'colour',
    Contribution: 'contribution',
    Copy: 'copy',
    Foundations: 'foundations',
    GettingStarted: 'getting-started',
    Luna: 'homepage',
    Icons: 'icons',
    Layout: 'layout',
    Resources: 'resources',
    Typography: 'typography',
  }
  const hasBackgroundImage = images.hasOwnProperty(pascalCase(title))

  return (
    <div
      className={`c-masthead c-masthead--bg c-masthead--bg__${
        images[pascalCase(title)]
      }`}
    >
      <Container soft={true} size="xs">
        <GridWrapper>
          <GridItem
            size={
              hasBackgroundImage ? { md: '1/2', lg: '4/6', xxl: '1/1' } : null
            }
          >
            <div className="c-masthead--wrapper">
              <Display6 as="h1" className="ds-theme--sainsburys">
                {overviewTitle ? overviewTitle : title}
              </Display6>
              <Body1>
                <ReactMarkdown linkTarget="_blank">{tagline}</ReactMarkdown>
              </Body1>
            </div>
          </GridItem>
        </GridWrapper>
      </Container>
    </div>
  )
}

export default Masthead
